import React from "react";
import StatusPage from "./components/StatusPage/StatusPage";
import Footer from "./components/Footer/Footer";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/Alert/AlertTemplate";

import "./App.css";
import Header from "./components/Header/Header";

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 3000,
  offset: "10px",
  transition: transitions.FADE,
  containerStyle: {
    fontSize: "0.75rem",
  },
};

const App = () => (
  <AlertProvider template={AlertTemplate} {...options}>
    <Header />
    <div className="main">
      <div className="app">
        <StatusPage />
        <Footer />
      </div>
    </div>
  </AlertProvider>
);

export default App;
