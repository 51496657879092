import React, { useEffect, useState } from "react";
import CollapsibleStatusCard from "../CollapsibleCard/CollapsibleStatusCard";
import "./StatusPage.css";
import StatusCard from "../StatusCard/StatusCard";
import OverallStatus from "../OverallStatus/OverallStatus";
import {
  getRSaaSDevStatus,
  getRSaaSQA3Status,
  getRSaaSUATStatus,
  getNCARBDevStatus,
  getNCARBQA3Status,
  getNCARBUATStatus,
  getRSaaSProdStatus,
  getNCARBProdStatus,
} from "../../api-utils/status-apis";
const StatusPage = () => {
  const [rsaasDevStatus, setRsaasDevStatus] = useState({ loading: "true" });
  const [rsaasQA3Status, setRsaasQA3Status] = useState({ loading: "true" });
  const [rsaasUATStatus, setRsaasUATStatus] = useState({ loading: "true" });
  const [ncarbDevStatus, setNcarbDevStatus] = useState({ loading: "true" });
  const [ncarbQA3Status, setNcarbQA3Status] = useState({ loading: "true" });
  const [ncarbUATStatus, setNcarbUATStatus] = useState({ loading: "true" });
  const [rsaasProdStatus, setRsaasProdStatus] = useState({ loading: "true" });
  const [ncarbProdStatus, setNcarbProdStatus] = useState({ loading: "true" });
  const refreshStatus = () => {
    setRsaasDevStatus({ loading: "true" });
    setRsaasQA3Status({ loading: "true" });
    setRsaasUATStatus({ loading: "true" });
    setNcarbDevStatus({ loading: "true" });
    setNcarbQA3Status({ loading: "true" });
    setNcarbUATStatus({ loading: "true" });
    getRSaaSDevStatus()
      .then((status) => setRsaasDevStatus(status))
      .catch((error) => setRsaasDevStatus(error));
    getRSaaSQA3Status()
      .then((status) => setRsaasQA3Status(status))
      .catch((error) => setRsaasQA3Status(error));
    getRSaaSUATStatus()
      .then((status) => setRsaasUATStatus(status))
      .catch((error) => setRsaasUATStatus(error));
    getRSaaSProdStatus()
      .then((status) => setRsaasProdStatus(status))
      .catch((error) => setRsaasProdStatus(error));
    getNCARBDevStatus()
      .then((status) => setNcarbDevStatus(status))
      .catch((error) => setNcarbDevStatus(error));
    getNCARBQA3Status()
      .then((status) => setNcarbQA3Status(status))
      .catch((error) => setNcarbQA3Status(error));
    getNCARBUATStatus()
      .then((status) => setNcarbUATStatus(status))
      .catch((error) => setNcarbUATStatus(error));
    getNCARBProdStatus()
      .then((status) => setNcarbProdStatus(status))
      .catch((error) => setNcarbProdStatus(error));
  };
  const refreshSingleStatus = (getter, setter) => {
    setter({ loading: "true" });
    getter()
      .then((status) => setter(status))
      .catch((error) => setter(error));
  };
  useEffect(() => refreshStatus(), []);

  return (
    <>
      <OverallStatus
        statusArray={[
          rsaasDevStatus,
          rsaasQA3Status,
          rsaasUATStatus,
          rsaasProdStatus,
          ncarbDevStatus,
          ncarbQA3Status,
          ncarbUATStatus,
        ]}
      />

      <div className="status-content-container">
        <div className="card-container">
          <div className="status-section">
            <div className="row-label">
              <div className="row-label-text">
                <span className="row-label-inner">RSaaS</span>
              </div>
            </div>
            <div className="status-section-box br-1c">
              <div className="collapsible-status-card bb-1c">
                <CollapsibleStatusCard
                  serviceName="DEV"
                  type="rsaas-dev"
                  refreshStatus={() =>
                    refreshSingleStatus(getRSaaSDevStatus, setRsaasDevStatus)
                  }
                  details={
                    <StatusCard status={rsaasDevStatus} type="rsaas-dev" />
                  }
                  status={rsaasDevStatus}
                />
              </div>
              <div className="collapsible-status-card">
                <CollapsibleStatusCard
                  serviceName="QA3"
                  type="rsaas-qa3"
                  refreshStatus={() =>
                    refreshSingleStatus(getRSaaSQA3Status, setRsaasQA3Status)
                  }
                  details={
                    <StatusCard status={rsaasQA3Status} type="rsaas-qa3" />
                  }
                  status={rsaasQA3Status}
                />
              </div>
            </div>
            <div className="status-section-box">
              <div className="collapsible-status-card bb-1c">
                <CollapsibleStatusCard
                  serviceName="UAT"
                  type="rsaas-uat"
                  refreshStatus={() =>
                    refreshSingleStatus(getRSaaSUATStatus, setRsaasUATStatus)
                  }
                  details={
                    <StatusCard status={rsaasUATStatus} type="rsaas-uat" />
                  }
                  status={rsaasUATStatus}
                />
              </div>
              <div className="collapsible-status-card">
                <CollapsibleStatusCard
                  serviceName="PROD"
                  type="rsaas-prod"
                  refreshStatus={() =>
                    refreshSingleStatus(getRSaaSProdStatus, setRsaasProdStatus)
                  }
                  details={
                    <StatusCard status={rsaasProdStatus} type="rsaas-prod" />
                  }
                  status={rsaasProdStatus}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-container mt-2r">
          <div className="status-section">
            <div className="row-label">
              <div className="row-label-text">
                <span className="row-label-inner">NCARB Wrapper</span>
              </div>
            </div>
            <div className="status-section-box br-1c">
              <div className="collapsible-status-card  bb-1c">
                <CollapsibleStatusCard
                  serviceName="DEV"
                  type="ncarb-dev"
                  refreshStatus={() =>
                    refreshSingleStatus(getNCARBDevStatus, setNcarbDevStatus)
                  }
                  details={
                    <StatusCard status={ncarbDevStatus} type="ncarb-dev" />
                  }
                  status={ncarbDevStatus}
                />
              </div>
              <div className="collapsible-status-card">
                <CollapsibleStatusCard
                  serviceName="UAT"
                  type="ncarb-uat"
                  refreshStatus={() =>
                    refreshSingleStatus(getNCARBUATStatus, setNcarbUATStatus)
                  }
                  details={
                    <StatusCard status={ncarbUATStatus} type="ncarb-uat" />
                  }
                  status={ncarbUATStatus}
                />
              </div>
            </div>
            <div className="status-section-box">
              <div className="collapsible-status-card  bb-1c">
                <CollapsibleStatusCard
                  serviceName="QA3"
                  type="ncarb-qa3"
                  refreshStatus={() =>
                    refreshSingleStatus(getNCARBQA3Status, setNcarbQA3Status)
                  }
                  details={
                    <StatusCard status={ncarbQA3Status} type="ncarb-qa3" />
                  }
                  status={ncarbQA3Status}
                />
              </div>

              <div className="collapsible-status-card">
                <CollapsibleStatusCard
                  serviceName="PROD"
                  type="ncarb-prod"
                  refreshStatus={() =>
                    refreshSingleStatus(getNCARBProdStatus, setNcarbProdStatus)
                  }
                  details={
                    <StatusCard status={ncarbProdStatus} type="ncarb-prod" />
                  }
                  status={ncarbProdStatus}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-container disabledWrapper mt-2r">
          <div className="status-section">
            <div className="row-label">
              <div className="row-label-text">
                <span className="row-label-inner">Generic Wrapper</span>
              </div>
            </div>
            <div className="status-section-box br-1c">
              <div className="collapsible-status-card  bb-1c">
                <CollapsibleStatusCard
                  serviceName="DEV"
                  refreshStatus={() => {
                    console.log("Refresh");
                  }}
                  details={<StatusCard />}
                  type="generic"
                />
              </div>
              <div className="collapsible-status-card">
                <CollapsibleStatusCard
                  serviceName="UAT"
                  refreshStatus={() => {}}
                  details={<StatusCard />}
                  type="generic"
                />
              </div>
            </div>
            <div className="status-section-box">
              <div className="collapsible-status-card  bb-1c">
                <CollapsibleStatusCard
                  serviceName="QA3"
                  refreshStatus={() => {}}
                  details={<StatusCard />}
                  type="generic"
                />
              </div>

              <div className="collapsible-status-card">
                <CollapsibleStatusCard
                  serviceName="PROD"
                  refreshStatus={() => {}}
                  details={<StatusCard />}
                  type="generic"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusPage;
