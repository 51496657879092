import moment from "moment";
const updateTime = (buildTime, setTimeElapsed, setTimeMax) => {
  if (buildTime) {
    const timeDiff = moment.duration(
      moment().diff(
        moment(buildTime.replace(" UTC", "Z"), "ddd MMM DD hh:mm:ssZ YYYY")
      )
    );

    const years = timeDiff.years();
    const months = timeDiff.months();
    const weeks = timeDiff.weeks();
    const days = timeDiff.days() - weeks * 7;
    const hours = timeDiff.hours();
    const minutes = timeDiff.minutes();
    const seconds = timeDiff.seconds();
    years > 0
      ? setTimeMax(`${years} year${years > 1 ? "s" : ""}`)
      : months > 0
      ? setTimeMax(`${months} month${months > 1 ? "s" : ""}`)
      : weeks > 0
      ? setTimeMax(`${weeks} week${weeks > 1 ? "s" : ""}`)
      : days > 0
      ? setTimeMax(`${days} day${days > 1 ? "s" : ""}`)
      : hours > 0
      ? setTimeMax(`${hours} hour${hours > 1 ? "s" : ""}`)
      : minutes > 0
      ? setTimeMax(`${minutes} minute${minutes > 1 ? "s" : ""}`)
      : seconds > 0
      ? setTimeMax(`${seconds} second${seconds > 1 ? "s" : ""}`)
      : setTimeMax("");
    setTimeElapsed(
      `${years > 0 ? `${years} year${years > 1 ? "s" : ""}, ` : ""}${
        months > 0 ? `${months} month${months > 1 ? "s" : ""}, ` : ""
      }${weeks > 0 ? `${weeks} week${weeks > 1 ? "s" : ""}, ` : ""}${
        days > 0 ? `${days} day${days > 1 ? "s" : ""}, ` : ""
      }${timeDiff.hours() > 0 ? `${hours} hour${hours > 1 ? "s" : ""}, ` : ""}${
        minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}, ` : ""
      }${seconds} second${seconds > 1 ? "s" : ""}`
    );
  }
};
export default updateTime;
