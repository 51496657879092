import { makeAPICall } from './request-helper';
import {
  RSAAS_DEV_ROUTE,
  RSAAS_QA3_ROUTE,
  RSAAS_UAT_ROUTE,
  NCARB_DEV_ROUTE,
  NCARB_QA3_ROUTE,
  NCARB_UAT_ROUTE,
  RSAAS_PROD_ROUTE,
  NCARB_PROD_ROUTE,
} from './routes/status-routes';

export const getRSaaSDevStatus = () => {
  return makeAPICall({
    url: RSAAS_DEV_ROUTE,
    method: 'GET',
  });
};

export const getRSaaSQA3Status = () => {
  return makeAPICall({
    url: RSAAS_QA3_ROUTE,
    method: 'GET',
  });
};

export const getRSaaSUATStatus = () => {
  return makeAPICall({
    url: RSAAS_UAT_ROUTE,
    method: 'GET',
  });
};

export const getRSaaSProdStatus = () => {
  return makeAPICall({
    url: RSAAS_PROD_ROUTE,
    method: 'GET',
  });
};

export const getNCARBDevStatus = () => {
  return makeAPICall({
    url: NCARB_DEV_ROUTE,
    method: 'GET',
  });
};

export const getNCARBQA3Status = () => {
  return makeAPICall({
    url: NCARB_QA3_ROUTE,
    method: 'GET',
  });
};

export const getNCARBUATStatus = () => {
  return makeAPICall({
    url: NCARB_UAT_ROUTE,
    method: 'GET',
  });
};

export const getNCARBProdStatus = () => {
  return makeAPICall({
    url: NCARB_PROD_ROUTE,
    method: 'GET',
  });
};
