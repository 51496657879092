import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer-container ">
      <div className="footer-status">
        Made with <i className="fas fa-solid fa-heart heartIcon" /> at{" "}
        <a href="https://www.datumbrain.com/" target="_blank" rel="noreferrer">
          Datum Brain
        </a>{" "}
        &copy; {new Date().getFullYear()}
      </div>
    </div>
  );
};

export default Footer;
