import React, { useState } from 'react';
import './StatusCard.css';
import updateTime from '../../utils/updateTime';
import {
  Card,
  CardTitle,
  CardGroup,
  Badge,
  Popover,
  PopoverBody,
} from 'reactstrap';

const StatusCard = ({ status, type, refreshStatus }) => {
  const [timeElapsed, setTimeElapsed] = useState('');
  const [timeMax, setTimeMax] = useState('');
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = (prevState) => setPopoverOpen(!prevState);
  let buildTime = status?.build_time;

  setInterval(() => {
    updateTime(buildTime, setTimeElapsed, setTimeMax);
  }, 100);

  return (
    <Card body>
      <table className='details-table'>
        <tbody>
          <tr>
            <td>
              {status?.services || status?.loading ? (
                <div className='ml-1px seviceStatus'>Last built</div>
              ) : (
                <div className='ml-1px seviceStatus'>Last built</div>
              )}
            </td>
            <td>
              <div className='ta-end mr-1px'>
                <span className='buildTime'>
                  {!status?.loading
                    ? status?.build_time
                      ? timeMax
                        ? `more than ${timeMax} ago`
                        : 'loading...'
                      : 'N/A'
                    : 'loading...'}
                </span>
                <i
                  id={`${type}-build-time`}
                  className='fas fa-question-circle info-mark'
                ></i>
                <Popover
                  placement='bottom'
                  isOpen={popoverOpen}
                  target={`${type}-build-time`}
                  toggle={toggle}
                  trigger='hover'
                >
                  <PopoverBody>
                    {!status?.loading
                      ? status?.build_time
                        ? `${timeElapsed} ago`
                        : status
                      : 'loading...'}
                  </PopoverBody>
                </Popover>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {status?.loading ? (
        <CardGroup>
          <Card body className='single-card'>
            <CardTitle>Loading...</CardTitle>
            <Badge color='loading'>loading...</Badge>
          </Card>
        </CardGroup>
      ) : status?.services ? (
        <CardGroup>
          {status?.services?.map((service) => (
            <Card key={ service?.name || service?.service_id } body>
              <CardTitle className='service-title ml-1px'>
                { service?.name || service?.service_id } { /* Remove .service_id part later */}
              </CardTitle>
              {service?.status ? (
                <Badge color='success'>connected</Badge>
              ) : (
                <Badge color='danger'>connection failed</Badge>
              )}
            </Card>
          ))}
        </CardGroup>
      ) : (
        <CardGroup>
          <Card body className='single-card card-red'>
            {' '}
            <CardTitle className='service-title ml-1px'>
              Not deployed yet
            </CardTitle>
            <Badge color='danger'>not connected</Badge>
          </Card>
        </CardGroup>
      )}
    </Card>
  );
};
export default StatusCard;
