import React, {useState} from "react";
import {Popover, PopoverBody} from "reactstrap";
import {useAlert} from "react-alert";
import useInterval from "../../utils/hooks/UseInterval";

import "./CollapsibleStatusCard.css";

const CollapsibleStatusCard = ({
                                   serviceName,
                                   refreshStatus,
                                   details,
                                   type,
                                   status,
                               }) => {
    const alert = useAlert();
    const [hashPopoverOpen, setHashPopoverOpen] = useState(false);
    const [statusPopoverOpen, setStatusPopoverOpen] = useState(false);
    const [lastCheckTime, setLastCheckTime] = useState(0);
    const toggleStatusPopover = () => setStatusPopoverOpen(!statusPopoverOpen);
    const toggleHashPopover = () => setHashPopoverOpen(!hashPopoverOpen);
    useInterval(() => {
        setLastCheckTime(lastCheckTime + 1);
    }, 60000);
    const handleRefresh = () => {
        refreshStatus();
        setLastCheckTime(0);
    };

    const header = (
        <div className="trigger-header">
            <div className="ml-8">
                <span className="serviceName">{serviceName}</span>
                &nbsp;
                <span
                    className="header-version"
                    id={`${type}-commit-span`}
                >
              [{status?.version}]
              <span
                  className="header-hash"
                  onClick={() => {
                      navigator.clipboard.writeText(status?.git_commit_hash?.short);
                      alert.show("Commit hash copied to clipboard.");
                  }}>({status?.git_commit_hash?.short})</span>
          </span>
            </div>
            <div className="statusIcon">
                {status?.loading ? (
                    <i
                        id={`${type}-status-icon`}
                        className="fas fa-circle-notch fa-spin mr-8"
                    />
                ) : (!status?.services && status?.rsaas_api_accessible) ||
                ((
                        status?.services && status?.services?.filter(({status}) => status)
                    )?.length === status?.services?.length &&
                    status?.services?.length) ? (
                    <i id={`${type}-status-icon`} className="fas fa-check-circle mr-8"/>
                ) : (
                    <i
                        id={`${type}-status-icon`}
                        className="fas fa-exclamation-triangle mr-8"
                    />
                )}
            </div>
        </div>
    );
    return (
        <>
            <div trigger={header} open>
                {header}
                {type === "generic" ? null : (
                    <Popover
                        placement="left"
                        isOpen={statusPopoverOpen}
                        target={`${type}-status-icon`}
                        toggle={toggleStatusPopover}
                        trigger="hover"
                    >
                        <PopoverBody>
                            Last checked:
                            <i>
                                {lastCheckTime < 1
                                    ? " just now "
                                    : ` ${lastCheckTime} min${lastCheckTime > 1 ? "s" : ""} ago`}
                            </i>
                            <i
                                className="fa fa-redo-alt refresh-btn ml-8"
                                onClick={handleRefresh}
                            />
                        </PopoverBody>
                    </Popover>
                )}

                {status?.git_commit_hash ? (
                    <Popover
                        placement="right"
                        isOpen={hashPopoverOpen}
                        target={`${type}-commit-span`}
                        toggle={toggleHashPopover}
                        trigger="hover"
                    >
                        <PopoverBody>
              <span
                  onClick={() => {
                      navigator.clipboard.writeText(status?.git_commit_hash?.short);
                      alert.show("Commit hash copied to clipboard.");
                  }}
                  className="popoverBody"
              >
                {status?.git_commit_hash?.long}
              </span>
                        </PopoverBody>
                    </Popover>
                ) : (
                    ""
                )}
                <div className="collapsible-card-body">{details}</div>
            </div>
        </>
    );
};

export default CollapsibleStatusCard;
