function _interopDefault(ex) {
  return ex && typeof ex === 'object' && 'default' in ex ? ex['default'] : ex;
}

var React = _interopDefault(require('react'));

var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

var alertStyle = {
  backgroundColor: '#151515',
  color: 'white',
  padding: '10px 15px 10px 15px',
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  boxSizing: 'border-box',
};

var AlertTemplate = function AlertTemplate(_ref) {
  var message = _ref.message,
    style = _ref.style;

  return React.createElement(
    'div',
    { style: _extends({}, alertStyle, style) },
    React.createElement('span', { style: { flex: 1 } }, message)
  );
};

module.exports = AlertTemplate;
