import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import refreshIcon from "../../assets/svg/refresh.svg";
import "./OverallStatus.css";
const OverallStatus = ({ statusArray }) => {
  const [overallStatus, setOverAllStatus] = useState("loading");
  const lastUpdateTime = new Date();
  useEffect(() => {
    let status = "loading";
    let okFlag = true;
    if (statusArray?.length) {
      for (let i = 0; okFlag && i < statusArray?.length; i++) {
        if (statusArray[i]?.loading) {
          status = "loading";
          okFlag = false;
        } else if (
          statusArray[i]?.services?.filter(({ status }) => status).length !==
          statusArray[i]?.services.length
        ) {
          console.log("Error on ", statusArray[i]);
          status = "error";
          okFlag = false;
        }
      }
      if (okFlag) setOverAllStatus("good");
      else setOverAllStatus(status);
    }
  }, [statusArray]);
  return (
    <div className="overallStatus-Wrapper">
      <div className="overall-container">
        <div className="header-status">
          <div>RSaaS API Status</div>
        </div>
        {overallStatus === "good" ? (
          <div className="overall-status-container status-good">
            <i className="fas fa-check" />
            <span>Everything is good</span>
          </div>
        ) : overallStatus === "loading" ? (
          <div className="overall-status-container status-loading">
            <i className="fas fa-circle-notch fa-spin fa-white" />
            <span>Loading...</span>
          </div>
        ) : (
          <div className="overall-status-container status-error">
            <i className="fas fa-exclamation" />
            <span>Something went wrong</span>
          </div>
        )}
        <div>
          <Button
            outline
            color="custom"
            className="refreshBtn"
            onClick={() => {
              window.location.reload();
            }}
          >
            <span className="refreshIcon">
              <img className="" src={refreshIcon} alt="Refresh Button" />
            </span>{" "}
            <span>Refresh</span>
          </Button>
        </div>
      </div>
      <div className="update-time">
        <em>
          {lastUpdateTime.toLocaleString([], {
            timeStyle: "short",
            dateStyle: "medium",
          })}
        </em>
        <span className="textLastUpdated">Last Updated: </span>
      </div>
    </div>
  );
};

export default OverallStatus;
